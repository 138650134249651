@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "TH Sarabun New";
    src: local("THSarabun"),
      url("/public/fonts/THSarabun.ttf") format("truetype");
  }
}

body {
  margin: 0;
  font-family: "TH Sarabun New", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

textarea {
  resize: none;
}

#end_user_comp {
  overflow: hidden;
}
